html, body, pre, code, kbd, samp {
    font-family: 'Press Start 2P', cursive;
  }

  body {
    background-color: #FEE500;
  }

  #areBrian, #priceUpdate4 {
    font-size: 65px;
  }
  @media only screen and (max-width: 1200px) {
    #areBrian, #priceUpdate4 {
      font-size: 55px;
    }
  }
  @media only screen and (max-width: 1000px) {
    #areBrian, #priceUpdate4 {
      font-size: 45px;
    }
  }
  @media only screen and (max-width: 800px) {
    #areBrian, #priceUpdate4 {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 500px) {
    #areBrian, #priceUpdate4 {
      font-size: 23px;
    }
  }